import { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout, Dropdown, Space, Button, Drawer } from 'antd';
import { LogoutOutlined, DoubleRightOutlined, DoubleLeftOutlined, UserOutlined, MenuOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import useWindowDimensions from '../../hooks/dimensions.hook';

import { logOut } from '../../redux/authentication/actionCreator';
import Menu from '../organisms/menu.component';
import FooterSection from '../organisms/footer.component';

type Props = {
  logOut: Function,
  user?:any,
}

const AdminLayout: FC<Props> = ({logOut, user}) => {
  const location = useLocation();
  const { Header, Footer, Sider, Content } = Layout;
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { width } = useWindowDimensions();
  useEffect(() => {
    const driftScript = document.createElement('script');
    driftScript.type = 'text/javascript';
    driftScript.async = true;
    driftScript.crossOrigin = 'anonymous';
    driftScript.innerHTML = `
      "use strict";

      !function() {
        var t = window.driftt = window.drift = window.driftt || [];
        if (!t.init) {
          if (t.invoked) return void (window.console && console.error && console.error("Drift snippet included twice."));
          t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ], 
          t.factory = function(e) {
            return function() {
              var n = Array.prototype.slice.call(arguments);
              return n.unshift(e), t.push(n), t;
            };
          }, t.methods.forEach(function(e) {
            t[e] = t.factory(e);
          }), t.load = function(t) {
            var e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
            o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
            var i = document.getElementsByTagName("script")[0];
            i.parentNode.insertBefore(o, i);
          };
        }
      }();
      drift.SNIPPET_VERSION = '0.3.1';
      drift.load('tx4xdytw89uw');
    `;
    document.head.appendChild(driftScript);

    return () => {
      // Clean up the script when the component is unmounted
      document.head.removeChild(driftScript);
    };
  }, []);

  const items: MenuProps['items'] = [
    {
      type: 'group',
      label: (
        <><span>{user.name}</span><br /><span>{user.email}</span></> 
      ),
      children: [
        {
          type: 'divider',
        },
        {
          key: 'closeSesion',
          label: (
            <a onClick={()=>handleCloseSesion()}>
              <LogoutOutlined /> Cerrar sesión
            </a>
          ),
        },
      ],
    },
  ];

  const handleCloseSesion = () => {
    logOut();
  };

  const onToggleCollapsed = () => {
    if (!isMobile) {
      setCollapsed(!collapsed);
    } else {
      setShowMobileMenu(!showMobileMenu);
    }
  };

  useEffect(()=>{
    if (width !== 0 && width <= 1024) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(()=>{
    if (isMobile) {
      setShowMobileMenu(false);
    }
  }, [location.pathname]);
  

  return (
    <div className="admin-layout">
      <Space direction="vertical" style={{ width: '100%' }} size={[0, 48]}>
        <Layout>
          <Header className="header">
            <>
              <div className="header__menu">
                <Button type="primary" onClick={onToggleCollapsed}>
                  {isMobile ? <MenuOutlined /> : collapsed ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
                </Button>
                <img className="header__logo" src={'/logo-small.png'}  alt="Trazolab" />
              </div>
              
              <div className="header__user__nav">
                <Dropdown menu={{ items }}>
                  <button onClick={(e) => e.preventDefault()} className="header__user__avatar"><UserOutlined /></button>
                </Dropdown>
              </div>
            </>
          </Header>
          <Layout hasSider>
            {!isMobile && (
              <Sider className="sidebar" collapsed={collapsed}>
                <Menu inlineCollapsed={collapsed} />
              </Sider>
            )}
            <Layout>
              <Content className="content">
                <Outlet />
              </Content>
              <Footer>
                <FooterSection />
              </Footer>
            </Layout>
          </Layout>
        </Layout>
      </Space>
      <Drawer title={<img className="header__logo"  src={'/logo-small.png'} alt="Trazolab" />} placement="left" onClose={()=>setShowMobileMenu(false)} open={showMobileMenu}>
        <Menu inlineCollapsed={false} />
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state:any) => ({
  user: state.users?.data || null,
});

export default connect(mapStateToProps, {logOut})(AdminLayout);