import { Outlet } from 'react-router-dom';
import { Col, Row } from 'antd';
import useWindowDimensions from '../../hooks/dimensions.hook';

const AuthLayout = () => {
const { width } = useWindowDimensions();

  return (
    <>
      <Row>
        <Col span={width <= 767 ? 24 : 12}>
          <div className="auth-layout__identity">
            <div className="auth-layout__identity__container">
              {width <= 767 ? (
                <img className="header__logo" src={'/logo-small.png'} alt="Trazolab LOGIN" />
              ) : (
                <img className="auth-layout__identity__logo" src="/logo.png" alt="Trazolab" />
              )}
            </div>
          </div>
        </Col>
        <Col span={width <= 767 ? 24 : 12}>
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

export default AuthLayout;
